import React from 'react';
import styles from './promotionpage.module.css'
import background from '../../assets/banners/BQCHENGZI_MENU_DESKTOP.jpg'
import button from '../../assets/home/boton_1-min.png'
import logoLai from '../../assets/home/logo_grande.png'
import fb from '../../assets/detailmenu/fb-min.png'
import ig from '../../assets/detailmenu/instagram-min.png'
//promociones
import promo1 from '../../assets/combos/Banquete Lai Lai_Web2025.png'
import promo2 from '../../assets/combos/Combo Chengzhi_Web2025.png'
import promo3 from '../../assets/combos/Combo Familiar_Web2025.png'
import promo4 from '../../assets/combos/Combos-D-FaSanKai.png'
import promo5 from '../../assets/combos/Super Banquete_Web2025.png'
import promo6 from '../../assets/combos/Super Combo_Web2025.png'
import promo7 from '../../assets/combos/Combo_Pollo_Asado_Web.png'

import Social from '../Social'
import Modal from '../Modal'

const img =
  [
    {
      image: promo1,
      id: "promo1",
    },
    {
      image: promo2,
      id: "promo2",
    },
    {
      image: promo3,
      id: "promo3",
    },
    /*{
      image: promo4,
      id: "promo4",
    },*/
    {
      image: promo5,
      id: "promo5",
    },
    {
      image: promo6,
      id: "promo6",
    },
   /*  {
      image: promo7,
      id: "promo7",
    } */

  ]

class PromotionPage extends React.Component {
  render() {
    return (
      <>
        <Modal />
        <Social />
        <div className={`row ${styles.background} `}>
          <div className="col col-sm col-lg">
            <div className="row " >
              <div className={`col col-sm col-lg ${styles.divImg}`}>
                <div className={styles.logoHolder}>
                  <div className={styles.divtopimg} >
                    <img src={background} alt="logo" />
                  </div>
                  <p className={styles.logoHoldertexttitle}>Combos</p>
                  <p className={styles.logoHoldertextsub}>Encuentra lo que buscas</p>
                </div>
                <hr />
              </div>
            </div>
            <div className={`row ${styles.row}`} >
              <div className={`col col-sm col-lg ${styles.divImgTitletop}`}>
                <div className={styles.logoHolder}>
                  <p className={styles.logoHoldertexttitlescroll}>Listado de Combos</p>
                </div>
              </div>
            </div>

            <div className={styles.mobile}>
              {
                img.map((column, key) => (
                <div className={`row`} key={key}>
                  <div className={`col col-sm col-lg align-self-center ${styles.divImgScroll}`}>
                    <div className={styles.divfull}>
                      <img
                        src={column.image}
                        className={styles.imgscroll}
                        alt="logo"
                        data-toggle="modal" data-target={`#${column.id}`}
                      />
                    </div>
                  </div>
                </div>
                ))
              }
            </div>
            <div className={styles.web}>
              <div className={`row`}>
                <div className={`col col-sm col-lg align-self-center ${styles.divImgScrollweb}`}>
                  <div className={styles.divfull}>
                    {
                      img.map((column, key) => (
                        <img
                          src={column.image}
                          className={styles.imgscrollweb}
                          alt="logo"
                          data-toggle="modal" data-target={`#${column.id}`}
                          key={key}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${styles.backgroundfooter} row justify-content-center`}>
            <div className={`col col-sm col-lg ${styles.mobile}`}>
              <div className="row">
                <div className={`col col-sm col-lg `}>
                  <img src={logoLai} className={styles.imagelogo} alt="logo" />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.firsttext} ></p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.copyright} >
                    2020 Copyrights - <span className={styles.colortextcopyright}>Lai Lai Guatemala</span>
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.textreservation} >
                    Visítanos en el siguiente horario:
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.schedule} >
                  Lu - Do 10:00 a 22:00
              </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col-2 col-sm-2 col-lg-1 offset-lg-6  offset-sm-3`}>
                  <div className={styles.imgicon}>
                    <a href="https://www.facebook.com/RestauranteLaiLai">
                      <img src={fb} alt="icon" />
                    </a>
                  </div>
                </div>
                <div className={`col-2 col-sm-2 col-lg-1 `}>
                  <div className={styles.imgicon}>
                    <a href="https://www.instagram.com/restaurantelailai/">
                      <img src={ig} alt="icon" />
                    </a>
                  </div>
                </div>
                <div className={`col-4 col-sm col-lg ${styles.buttonCall}`}>
                  <a href="tel:1733" className={styles.buttonHolder}>
                    <img src={button} alt="logo" className={styles.imgCall} />
                  </a>
                </div>
              </div>
            </div>
            <div className={`col col-sm col-lg ${styles.desktop}`}>
              <div className="row">
                <div className={`col col-sm col-lg `}>
                  <img src={logoLai} className={styles.imagelogo} alt="logo" />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.firsttext}> </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.copyright} >
                    2020 Copyrights - <span className={styles.colortextcopyright}>Lai Lai Guatemala</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={`col col-sm col-lg ${styles.desktop} `}>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.textreservation} >
                    Visítanos en el siguiente horario:
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col col-sm col-lg `}>
                  <p className={styles.schedule} >
                  Lu - Do 10:00 a 22:00
              </p>   
                </div>
              </div>
              <div className="row ">
                <div className={`col col-sm-2 col-lg-1 offset-lg-6  offset-sm-3`}>
                  <div className={styles.imgicon}>
                    <a href="https://www.facebook.com/RestauranteLaiLai">
                      <img src={fb} alt="icon" />
                    </a>
                  </div>
                </div>
                <div className={`col col-sm-2 col-lg-1 `}>
                  <div className={styles.imgicon}>
                    <a href="https://www.instagram.com/restaurantelailai/">
                      <img src={ig} alt="icon" />
                    </a>
                  </div>
                </div>
                <div className={`col col-sm-4 col-lg-2 `}>
                  <a href="tel:1733" className={styles.buttonHolder}>
                    <img src={button} alt="logo" className={styles.imgCall} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default PromotionPage;

