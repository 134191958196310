
const calientes = {
  1:{
    "name" : "Té Poley (Jarra)",
    "price": "Q20.00"
  },
  2:{
    "name" : "Té Poley (Libra)",
    "price": "Q140.00"
  },
  3:{
    "name" : "Té Jazmín (Jarra)",
    "price": "Q20.00"
  },  
  4:{
    "name" : "Té Lipton",
    "price": "Q10.00"
  },
  5:{
    "name" : "Café Capuchino",
    "price": "Q16.00"
  },
  6:{
    "name" : "Café Expreso",
    "price": "Q12.00"
  },
  7:{
    "name" : "Café Americano",
    "price": "Q12.00"
  },
  8:{
    "name" : "Café con Crema",
    "price": "Q12.00"
  },
  9:{
    "name" : "Café con Leche",
    "price": "Q12.00"
  },
}
export {calientes};