import React from 'react';
import styles from './promotion.module.css'
//promociones
import promo1 from '../../assets/combos/Banquete Lai Lai_Web2025.png'
import promo2 from '../../assets/combos/Combo Chengzhi_Web2025.png'
import promo3 from '../../assets/combos/Combo Familiar_Web2025.png'
import promo4 from '../../assets/combos/Combos-D-FaSanKai.png'
import promo5 from '../../assets/combos/Super Banquete_Web2025.png'
import promo6 from '../../assets/combos/Super Combo_Web2025.png'
//import promo7 from '../../assets/combos/Combo_Pollo_Asado_Web.png'



const img =
  [
    {
      image: promo1
    },
    {
      image: promo2
    },
    {
      image: promo3
    },
    //{
      //image: promo4
    //},
    {
      image: promo5
    },
    {
      image: promo6
    }

  ]

class Footer extends React.Component {
  render() {
    return (
      <div className={`row ${styles.background} row justify-content-center`}>
        <div className={`col col-sm col-lg`}>
          <div className="row">
            <div className={`col col-sm col-lg `}>
              <p className={styles.texttitlepromo}>Combos</p>
            </div>
            <div className={`col col-sm col-lg  ${styles.divpromotext}`}>
              <a href="/Promociones">
                <p className={styles.textpromo} >Ir a Combos</p>
              </a>
            </div>
          </div>
          <div className={`row ${styles.mobile}`}>
            {img.map((column) => (
              <div className={`col-10 offset-1 col-sm col-lg align-self-center ${styles.divImgScroll}`}>
                <div className={styles.divfull}>
                  <img src={column.image} className={styles.imgscroll} alt="logo" />
                </div>
              </div>
            ))}
          </div>
          <div className={`row ${styles.web}`}>
            <div className={`col col-sm-10 offset-sm-1 col-lg-10 align-self-center ${styles.divImgScrollweb}`}>
              <div className={styles.divfull}>
                {img.map((column) => (
                  <img src={column.image} className={styles.imgscrollweb} alt="logo" />
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Footer;

